<template>
  <div>
    <KTPortlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-card :disabled="isProcessing" :loading="isProcessing">
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="success"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <v-toolbar-title>
                {{ $t("ALIASES.SUB_TITLE") }}
              </v-toolbar-title>
            </v-tab>
            <v-spacer></v-spacer>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <v-form>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="alias.category"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="alias.type"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" offset="1" sm="10">
                        <Table
                          :key="tableKey"
                          ref="alias-details-table"
                          :name="'alias-details-table-' + $route.params.id"
                          :headers="headers"
                          :server-data-options="{
                            method: tableItemsMethod,
                            params: { id: $route.params.id }
                          }"
                          dense
                          fixed-header
                          multi-sort
                          filterable
                          @overwritten-table-items="itemsForUpdate = {}"
                        >
                          <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
                            <EditableAliasTableItem
                              :key="item.item.id"
                              v-if="header.value === 'alias.value'"
                              :item="item.item"
                              :new-value="itemsForUpdate[item.item.id]?.aliasValue"
                              @item-updated="handleAliasDetailUpdated"
                              @item-update-cancelled="handleAliasDetailUpdateCancelled"
                            />
                            <span
                              :key="index"
                              v-else-if="header.value === 'vendor_offers_count'"
                              class="set-cursor-pointer"
                              @click="handleAliasDetailNumberClick(item.item)"
                            >
                              {{ item.value }}
                            </span>
                            <span
                              :key="index"
                              v-else-if="header.value === 'options' && item.item.vendor_offers_count == 0"
                            >
                              <v-btn
                                class="mx-2"
                                icon
                                color="red"
                                @click="handleDeleteAliasClick(item.item.id)"
                              >
                                <v-icon dark>
                                  mdi-trash-can
                                </v-icon>
                              </v-btn>
                            </span>
                            <template v-else>
                              {{ item.value }}
                            </template>
                          </template>
                        </Table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" v-on:click="back(true)">Back</v-btn>
                  <v-btn color="primary" v-on:click="store()">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <Confirm
            v-model="deleteConfirm"
            :content="$t('GENERAL.DELETE_CONFIRM')"
            @confirm="deleteAlias"
            @decline="deleteConfirm = false"
          />
        </v-card>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TableService from "@/common/table.service";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SHOW_SNACK } from "@/store/snack.module";
import Table from "@/views/partials/v-table/Table.vue";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import EditableAliasTableItem from "@/views/pages/aliases/partials/EditableAliasTableItem.vue";
import {
  GET_ALIAS,
  GET_ALIAS_DETAILS,
  UPDATE_ALIAS_DETAILS,
  DELETE_ALIAS_DETAIL
} from "@/store/aliases.module";

export default {
  components: { KTPortlet, Table, EditableAliasTableItem, Confirm },
  name: "EditAliases",
  data() {
    return {
      tab: "tab-1",
      tableKey: 1,
      isProcessing: true,
      alias: {},
      itemsForUpdate: {},
      tableItemsMethod: GET_ALIAS_DETAILS,
      deleteConfirm: false,
      aliasIdForDelete: null,
      headers: [
        {
          text: this.$t('ALIASES.TABLE_COLUMNS.VALUE'),
          value: 'value',
          align: 'center',
          width: '30%'
        },
        {
          text: this.$t('ALIASES.TABLE_COLUMNS.FORMATTED_VALUE'),
          value: 'alias.value',
          align: 'center',
          width: '30%'
        },
        {
          text: this.$t('ALIASES.TABLE_COLUMNS.NUMBER_OF_TYPE'),
          value: 'vendor_offers_count',
          align: 'center',
          width: '30%',
        },
        {
          text: this.$t('GENERAL.TABLE.OPTIONS_COLUMN'),
          value: 'options',
          align: 'center',
          sortable: false,
          width: '10%',
        },
      ],
    };
  },
  mounted() {
    this.selectAlias();
  },
  methods: {
    selectAlias() {
      this.isProcessing = true;

      this.$store
        .dispatch(GET_ALIAS, this.$route.params.id)
        .then((response) => {
          this.alias = response;

          this.isProcessing = false;
        });
    },

    store() {
      this.isProcessing = true;

      let hasEmptyValues = false;
      Object.values(this.itemsForUpdate).forEach(item => {
        if (item && !item.aliasValue) {
          hasEmptyValues = true;
        }
      });

      if (hasEmptyValues) {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('ALIASES.EMPTY_VALUE_ERROR_MESSAGE')
        });

        this.isProcessing = false;

        return;
      }

      this.$store
        .dispatch(UPDATE_ALIAS_DETAILS, {
          id: this.$route.params.id,
          params: {
            details: this.itemsForUpdate
          },
        })
        .then(() => {
          this.tableKey++;
          this.itemsForUpdate = {};
          this.isProcessing = false;
        })
        .catch((response) => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleAliasDetailUpdated(item) {
      this.itemsForUpdate[item.id] = {
        id: item.id,
        aliasValue: item.aliasValue
      };
    },

    handleAliasDetailUpdateCancelled(id) {
      this.itemsForUpdate[id] = undefined;
    },

    handleAliasDetailNumberClick(detail) {
      let state = TableService.getState('VueTable_vendorOffersTable_preferences');
      state.meta.search = "";
      state.filterScenarios = [{
          active:true,
          edit:true,
          filters:[
          {
            component: "string",
            data: `tag_values_by_category.${detail.tag_category_id}`,
            label: detail.tag_category?.name + " original value",
            mode: "exactMatch",
            type: "string",
            value: detail.value,
          },
          {
            component: "string",
            data: `vendor_offers.tags->${detail.tag_category_id}`,
            label: detail.tag_category?.name,
            mode: "exactMatch",
            type: "string",
            value: detail.alias.value
          }
        ],
          name: `Alias list "${detail.value}"`
      }];
      TableService.setState(state, 'VueTable_vendorOffersTable_preferences')
      let routeData = this.$router.resolve({
        name: 'list-offers'
      });
      window.open(routeData.href, "_blank");

      this.isProcessing = false;
    },

    handleDeleteAliasClick(id) {
      this.aliasIdForDelete = id;
			this.deleteConfirm = true;
    },

    deleteAlias() {
      this.$store
        .dispatch(DELETE_ALIAS_DETAIL, this.aliasIdForDelete)
        .then(() => {
          this.$refs['alias-details-table'].deleteServerItem(this.aliasIdForDelete);
          delete this.itemsForUpdate[this.aliasIdForDelete];
          this.isProcessing = false;
        })
        .catch((response) => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    back() {
      this.$router.push({ name: "aliases" });
    }
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="d-flex" v-if="isEditMode">
      <v-text-field
        ref="input"
        solo
        dense
        :value="newValue || item.alias.value"
        @input="handleValueChange($event)"
        @keydown.esc="handleCancelClick()"
      ></v-text-field>
      <v-icon
        color="red"
        class="ml-3 set-cursor-pointer"
        @click.native="handleCancelClick()"
      >
        mdi-close
      </v-icon>
    </div>
    <div
      v-else
      class="set-cursor-pointer"
      @click="handleItemClick()"
    >
      {{ item.alias.value }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    selectItems: {
      type: Array,
      default: () => []
    },

    newValue: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      isEditMode: this.newValue !== undefined
    }
  },

  watch: {
    newValue() {
      this.isEditMode = this.newValue !== undefined;
    }
  },

  methods: {
    handleItemClick() {
      this.isEditMode = true;

      this.$nextTick(() => {
        this.$refs.input.focus();
        this.$nextTick(() => {
          this.$refs.input.$refs.input.select();
        });
      });
    },

    handleValueChange(value) {
      this.$emit('item-updated', {
        id: this.item.id,
        aliasValue: value
      });
    },

    handleCancelClick() {
      this.isEditMode = false;

      this.$emit('item-update-cancelled', this.item.id);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field__details) {
  display: none;
}
::v-deep(.v-input__slot) {
  margin-bottom: 0 !important;
}
</style>